import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import './cancel.css';
import Img from 'gatsby-image';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { any } from 'prop-types';
import { Route } from '../models/site-links';

const CancelPage = () => {
  const data = useStaticQuery(graphql`
    query {
      radioselected: file(relativePath: { eq: "cancel/radio-button-selected.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      radio: file(relativePath: { eq: "cancel/radio-button.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      radioSelected: file(relativePath: { eq: "cancel/radio-button-selected.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo: file(relativePath: { eq: "cancel/PS Logo_Horizontal_Gradient_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      call: file(relativePath: { eq: "cancel/call.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      mail: file(relativePath: { eq: "cancel/mail.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  interface CustomerInfo {
    success: boolean;
    data: {
      customerName: string;
      presentOffers: boolean;
    };
  }

  let param;
  param = typeof window === 'undefined' ? 'undefined' : window!.location.search;

  const urlParams = new URLSearchParams(param);

  const id = urlParams.get('id');

  let customerInfo: CustomerInfo;

  const [cancelReason, setCancelReason] = useState<number>();
  const [giveOffers, setGiveOffers] = useState(Boolean);
  const [lookupSuccess, setLookupSuccess] = useState(Boolean);
  const [isLoading, setIsLoading] = useState(true);

  let sessionID;
  if (typeof crypto !== 'undefined') {
    sessionID = crypto.randomUUID();
  } else if (typeof window !== 'undefined') {
    // navigate to contact support
    navigate(Route.Cancel);
  }

  const formDataReason = {
    accountId: id,
    sessionId: sessionID,
    reasonForCancellation: cancelReason,
  };

  const formDataFinalize = {
    accountId: id,
    sessionId: sessionID,
    cancellationDetails: '',
  };

  const validateCustomer = async () => {
    const customerRequest = await axios.get(
      'https://core.prod.patronscan.servallapps.com/api/v1/customer/public-id/' + id,
    );

    customerInfo = customerRequest.data;

    setLookupSuccess(customerInfo.success);
    setGiveOffers(customerInfo.data.presentOffers);
  };

  const submitForm = async (reason) => {
    if (reason == 5) {
      axios.post(
        'https://core.prod.patronscan.servallapps.com/api/v1/cancel/reason/',
        formDataReason,
      );
      axios.post(
        'https://core.prod.patronscan.servallapps.com/api/v1/cancel/finalize/',
        formDataFinalize,
      );
    } else {
      axios.post(
        'https://core.prod.patronscan.servallapps.com/api/v1/cancel/reason/',
        formDataReason,
      );
    }
  };

  // Function below is to dynamically change the onclick event if the user selects the "permanently closed" option.
  // We will skip their navigation to the end of the flow if this is the case.

  const submitButton = (cancelReason) => {
    if (cancelReason == undefined) {
      return (
        <button className="button" onClick={(e) => submitForm(cancelReason)}>
          <AniLink
            fade
            className="block justify-end ml:8 hover:text-grey-dark transition-color lg:text-base"
            to="/cancel-options"
            state={{ id, cancelReason, sessionID }}
          >
            <Button
              buttonColor={ButtonColor.Primary}
              buttonStyle={ButtonStyle.CancelButton}
              text={'Submit'}
              buttonId={'CancelButton'}
            />
          </AniLink>
        </button>
      );
    }
    if (cancelReason == 5) {
      const cancelOrStay = 'cancel';
      return (
        <button className="button" onClick={(e) => submitForm(cancelReason)}>
          <AniLink
            fade
            className="block justify-end ml:8 hover:text-grey-dark transition-color lg:text-base"
            to="/cancel-success"
            state={{ id, cancelReason, sessionID, cancelOrStay }}
          >
            <Button
              buttonColor={ButtonColor.Primary}
              buttonStyle={ButtonStyle.CancelButton}
              text={'Submit'}
              buttonId={'CancelButton'}
            />
          </AniLink>
        </button>
      );
    }
    if (cancelReason != 5 && giveOffers == false) {
      return (
        <button className="button" onClick={(e) => submitForm(cancelReason)}>
          <AniLink
            fade
            className="block justify-end ml:8 hover:text-grey-dark transition-color lg:text-base"
            to="/cancel-why"
            state={{ id, cancelReason, sessionID, giveOffers }}
          >
            <Button
              buttonColor={ButtonColor.Primary}
              buttonStyle={ButtonStyle.CancelButton}
              text={'Submit'}
              buttonId={'CancelButton'}
            />
          </AniLink>
        </button>
      );
    } else {
      return (
        <button className="button" onClick={(e) => submitForm(cancelReason)}>
          <AniLink
            fade
            className="block justify-end ml:8 hover:text-grey-dark transition-color lg:text-base"
            to="/cancel-options"
            state={{ id, cancelReason, sessionID, giveOffers }}
          >
            <Button
              buttonColor={ButtonColor.Primary}
              buttonStyle={ButtonStyle.CancelButton}
              text={'Submit'}
              buttonId={'CancelButton'}
            />
          </AniLink>
        </button>
      );
    }
  };

  useEffect(() => {
    validateCustomer();

    setTimeout(async () => {
      setIsLoading(false);
    }, 2500);
  });

  if (isLoading == true) {
    return (
      <div>
        <div className="flex justify-center">
          <div className="container">
            <div style={{ marginTop: '530px', marginBottom: '670px' }}>
              <div className="mb-20 mt-10">
                <FontAwesomeIcon
                  className="spinner"
                  icon={faSpinner}
                  color="#507EE0"
                  pulse
                  size="6x"
                />
                <p className="loading-text">Please wait a moment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (lookupSuccess == true) {
    return (
      <div>
        <div style={{ width: '100vw' }}>
          <div className="flex justify-center">
            <div className="mb-12 mt-10">
              <p className="header">Cancel Subscription{isLoading}</p>
              <p className="subheader">Why have you decided to cancel your subscription?</p>
              <div className="cards mt-8">
                <button className="card my-3" onClick={(e) => setCancelReason(1)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">I'm not seeing the results I want</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(10)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">I don't feel Patronscan is catching enough fake IDs</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(2)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">I found another provider</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(3)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">I no longer need this product</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(4)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">My business is temporarily closed</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(5)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">My business is permanently closed</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(6)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">I don't feel valued as a customer</p>
                </button>

                <button className="card my-3" onClick={(e) => setCancelReason(8)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">I'm unhappy with Patroncan's level of support</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(9)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">It's too expensive</p>
                </button>
                <button className="card my-3" onClick={(e) => setCancelReason(7)}>
                  <Img
                    className="radio-selected"
                    fluid={data.radioSelected.childImageSharp.fluid}
                  />
                  <Img className="radio-unselected" fluid={data.radio.childImageSharp.fluid} />
                  <p className="card-text">Other</p>
                </button>
              </div>

              {submitButton(cancelReason)}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (lookupSuccess == false) {
    return (
      <div>
        <div className="flex justify-center">
          <div className="container">
            <div style={{ marginTop: '150px', marginBottom: '250px' }}>
              <div className="mb-20 mt-10">
                <p className="header">Contact Support</p>
                <p className="subheader">
                  Your account couldn't be found. Please contact our customer support team to help
                  at:
                </p>
                <div className="image-containers">
                  <div className="call-container">
                    <Img className="icon-image" fluid={data.call.childImageSharp.fluid} />
                    <p>1-(877)-778-9798 ext 2</p>
                  </div>
                  <div className="mail-container">
                    <Img className="icon-image" fluid={data.mail.childImageSharp.fluid} />
                    <p>support@patronscan.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default CancelPage;
